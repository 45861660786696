<!--
 * @Description: 发起签单
 * @Author: zhang zhen
 * @Date: 2024-08-31 11:53:33
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-09 15:00:50
 * @FilePath: /page-sass/src/views/ContractManagement/modules/reNewSign.vue
-->
<template>
  <a-modal
    title="请选择合同签署方式"
    :width="600"
    okText="确认"
    :visible="visible"
    centered
    @ok="handleOk"
    @cancel="handleClose"
  >
    <a-radio-group v-model="jumpType">
      <a-radio :style="radioStyle" value="1"> 在线签署 </a-radio>
      <div class="desc">合作双方通过平台在线签署合同</div>
      <a-radio :style="radioStyle" value="2"> 线下签署 </a-radio>
      <div class="desc">双方已完成线下纸质合同签署，直接上传合同文件</div>
    </a-radio-group>
  </a-modal>
</template>

<script>
import { postAction } from '@/api/manage'
export default {
  name: 'reNewSign',
  data() {
    return {
      infoObj: {},
      visible: false,
      jumpType: '1',
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
    }
  },
  methods: {
    handleOpen(item) {
      this.visible = true
      this.infoObj = { ...item }
      console.log('this.info', this.infoObj)
    },
    handleOk() {
      if (this.jumpType == '1') {
        console.log('this.info22', this.infoObj)
        this.$emit('changeType', {
          type: this.jumpType,
          record: {...this.infoObj},
        })
        this.handleClose();
      } else {
        postAction('/v1/contract/framework/initiate', {
          contractContent: '',
          contractNo: this.infoObj.contractNo,
          signType: this.jumpType,
        }).then((res) => {
          const { data, success, message } = res;
          if (success) {
            this.$emit('ok')
            // 接口
            this.$emit('changeType', {
              type: this.jumpType,
              record: data || this.infoObj,
            })
            this.handleClose();
          } else {
            this.$message.warning(message)
          }
        })
      }
    },
    handleClose() {
      this.jumpType = '1'
      this.infoObj = {}
      this.visible = false
    },
  },
}
</script>

<style lang="less" scoped>
.desc {
  padding-left: 26px;
  // font-size: 16px;
  color: #8c8c8c;
  font-weight: 400;
  line-height: 24px;
  margin-top: 2px;
  margin-bottom: 34px;
  &:last-of-type {
    margin-bottom: 0;
  }
}
</style>
