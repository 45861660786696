<template>
  <div class="infoCard">
    <div class="card-info-box">
      <div class="searchInfo">
        <a-input
          v-model="queryParams.contractNo"
          placeholder="请输入合同编号或企业名称"
          style="width: 480px; height: 38px; line-height: 38px"
        />
        <div class="option-area">
          <a-button type="primary" @click="handleSearch" style="width: 81px">查询</a-button>
          <a-button @click="reaLoad" style="width: 81px">重置</a-button>
        </div>
      </div>
      <a-form-model
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        labelAlign="left"
        style="margin-top: 8px"
        :colon="false"
      >
        <a-form-model-item label="合作状态">
          <checkboxSearch v-model="queryParams.cooperationStatus" :options="quoteOptions" :needLoad="false" />
        </a-form-model-item>
        <a-form-model-item label="创建时间">
          <radioGroup
            v-model="createTimeScoped"
            :options="otherOptions"
            :needLoad="false"
            @change="handleChangeRadio($event, 'createTime')"
          >
            <a-radio :value="-1">
              <a-range-picker
                v-model="createTimeRange"
                value-format="YYYY-MM-DD HH:mm:ss"
                separator="⇀"
                showTime
                @change="handleChangeDate($event, 'createTimeScoped')"
                style="width: 310px; height: 26px"
              >
                <span class="pickerDict">
                  <span v-if="createTimeRange.length">{{ createTimeRange[0] }} ~ {{ createTimeRange[1] }}</span>
                  <span class="placeholder" v-else>请选择</span>
                  <a-icon type="caret-down" style="margin-left: 8px; color: #999"></a-icon>
                </span>
              </a-range-picker>
            </a-radio>
          </radioGroup>
        </a-form-model-item>
        <a-form-model-item label="开始时间">
          <JDate placeholder="请选择" style="width: 140px" v-model="queryParams.startDate" />
        </a-form-model-item>
        <a-form-model-item label="结束时间">
          <JDate placeholder="请选择" style="width: 140px" v-model="queryParams.endDate" />
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="card-info-box">
      <a-table
        :columns="columns"
        :loading="loading"
        :data-source="dataSource"
        :pagination="false"
        style="margin-bottom: 16px"
        :scroll="{ x: 'max-content' }"
        rowKey="index"
      >
        <template slot="purchaseBusinessName" slot-scope="text, record">
          <div class="inline-name">
            <span class="tag" v-if="record.purchaseSignTime">已签署</span>
            <span class="tag danger" v-else>未签署</span>
            <a-tooltip>
              <template slot="title">{{ text }} </template>
              <span class="label">{{ text }}</span>
            </a-tooltip>
          </div>
        </template>
        <template slot="supplierBusinessName" slot-scope="text, record">
          <div class="inline-name">
            <span class="tag" v-if="record.supplierSignTime">已签署</span>
            <span class="tag danger" v-else>未签署</span>
            <a-tooltip>
              <template slot="title">{{ text }} </template>
              <span class="label">{{ text }}</span>
            </a-tooltip>
          </div>
        </template>
        <template slot="rangeTime" slot-scope="text, record">
          {{ record.startDate || '-' }} {{ record.endDate ?  ' ~ ' : '' }} {{ record.endDate || '' }}
        </template>
        <template slot="status" slot-scope="text, record">
          <span class="table-status-btn" :class="statusADictStyle[text + '']">
           {{ (statusADictList[text + '']) || '' }}
          </span>
        </template>
        <template slot="cooperationStatus" slot-scope="text, record">
          <span class="table-status-btn" :class="statusDictStyle[text + '']">
            {{ (text && statusDictList[text + '']) || '' }}
          </span>
        </template>
        <template slot="operation" slot-scope="text, record">
          <!-- 发起合同 采购商， 不能过期 -->

          <a v-if="setUserInfo().tradeIdentity != 0 && record.status == 0" @click="handleEmitSign(record)"><a-icon type="block" /> 发起合同</a>
          <!-- 签署时候在线/线下 -->
          <template v-if="record.status == 1">
            <a v-if="record.signType == 1" @click="handleChangeType({record})"><a-icon type="highlight" /> 签署</a>
            <a v-else @click="handleUploadContract(record)"><a-icon type="upload" /> 上传合同</a>
          </template>
          <template v-if="record.status == 2">
            <a @click="handleViewInfo(record)"><a-icon type="eye" /> 查看合同</a>
          </template>
        </template>
      </a-table>
      <ZPagination
        :total="pageNation.total"
        :current="pageNation.pageNum"
        :pageSize="pageNation.pageSize"
        @handlePageChange="handleChangePage"
      />
    </div>
    <UploadContract ref="UploadContract" :orderNo="orderNo" :tradeIdentity="setUserInfo().tradeIdentity" @ok="handleLoadData(1)" />
    <newUploader ref="newUploader" :orderNo="orderNo" :tradeIdentity="setUserInfo().tradeIdentity" @ok="handleLoadData(1)" @changeType="handleChangeType" />
    <ReNewSign ref="ReNewSignRef" @changeType="handleChangeKind" @ok="handleLoadData(1)" />
    <viewContract ref="viewContract" />
    <signContractInfo ref="signContractInfo" @changeType="handleChangeType" @ok="handleLoadData(1)" />
  </div>
</template>

<script>
import ZPagination from '@/components/plugins/ZPagination.vue'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import ListMixin from '@/mixins/listViewLoader'
import { mapGetters } from 'vuex'
import { postAction, getAction, uploadAction } from '@/api/manage'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import radioGroup from '@/components/plugins/radioGroup.vue'
import { getDateNewRange } from '@/util/utils'
import JDate from '@/components/easyComponents/JDate.vue'
import UploadContract from './modules/LastContractModal.vue'
import newUploader from './modules/newUploader.vue'
import viewContract from './modules/viewContract.vue'
import ReNewSign from './modules/reNewSign.vue'
import signContractInfo from './modules/signContractInfo.vue'
export default {
  name: 'ContractManagement',
  mixins: [ListMixin],
  components: {
    ZPagination,
    EmptyArea,
    checkboxSearch,
    radioGroup,
    JDate,
    UploadContract,
    newUploader,
    viewContract,
    ReNewSign,
    signContractInfo
  },

  data() {
    return {
      showMore: false,
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      queryParams: {
        status: undefined,
      },
      rangeDate: [],
      columns: [
        {
          title: '合同编号 ',
          dataIndex: 'contractNo',
          key: 'contractNo',
          width: 218,
        },
        {
          title: '采购方',
          dataIndex: 'purchaseBusinessName',
          key: 'purchaseBusinessName',
          width: 180,
          scopedSlots: { customRender: 'purchaseBusinessName' },
        },

        {
          title: '供应方',
          dataIndex: 'supplierBusinessName',
          key: 'supplierBusinessName',
          width: 180,
          scopedSlots: { customRender: 'supplierBusinessName' },
        },
        {
          title: '合作时间',
          dataIndex: 'rangeTime',
          align: 'center',
          key: 'rangeTime',
          width: 240,
          scopedSlots: { customRender: 'rangeTime' },

        },
        {
          title: '签署状态',
          dataIndex: 'status',
          key: 'status',
          width: 140,
          align: 'center',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '合作状态',
          dataIndex: 'cooperationStatus',
          key: 'cooperationStatus',
          width: 140,
          align: 'center',
          scopedSlots: { customRender: 'cooperationStatus' },
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: 180,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 170,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      quoteOptions: [
        {
          dictKey: '1',
          dictValue: '待生效',
        },
        {
          dictKey: '2',
          dictValue: '进行中',
        },
        {
          dictKey: '3',
          dictValue: '已过期',
        },
      ],
      otherOptions: [
        {
          dictKey: '0',
          dictValue: '本月',
        },
        {
          dictKey: '3',
          dictValue: '三个月',
        },
        {
          dictKey: '4',
          dictValue: '近半年',
        },
      ],
      mainOptions: [],
      activeInfo: {},
      requestType: 'post',
      url: {
        list: '/v1/contract/v1/contract/pageList',
      },
      statusDictList: {
        2: '进行中',
        1: '待生效',
        3: '已过期',
        4: '已终止',
      },
      statusDictStyle: {
        2: 'status_4',
        1: 'status_2',
        3: 'status_10',
        4: 'status_10',
      },
      statusADictList: {
        0: '待生效',
        1: '待签署',
        2: '已签署 '
      },
      //  0:发起 1:签署中 2 签署完成
      statusADictStyle: {
        0: 'status_1',
        1: 'status_6',
        2: 'status_4'
      },
      createTimeScoped: '',
      createTimeRange: [],
      orderNo: null,
      disableMixinCreated: true,
    }
  },
  created() {
    this.handleInit();
    if (this.$route.query && this.$route.query.contractNo) {
      this.queryParams.contractNo = this.$route.query.contractNo
      this.handleLoadData()
    } else {
      this.handleLoadData(1);
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleChangeKind({ type, record }) {
      if (type == 1) {
        console.log(record, 111)
        this.$refs.signContractInfo.handleOpen(record)
      } else {
        this.handleUploadContract(record)
      }
    },
    handleChangeType({ record }) {
      this.$refs.UploadContract.handleOpen(record)

    },
    handleEmitContract(e) {
      this.$refs.signContractInfo.handleOpen(e);
    },
    handleViewInfo(item) {
      this.$refs.viewContract.handleOpen(item)
    },
    handleChangeRadio(e, key) {
      if (e != '-1') {
        this[`${key}Range`] = []
      }
    },
    handleChangeDate(e, key) {
      if (e && e.length) {
        this[key] = -1
      }
    },
    handleInit() {
      getAction('/quote/dictionary').then((res) => {
        const { success, data } = res
        success && (this.mainOptions = data)
      })
    },
    handleJump(url) {
      window.open(url)
    },
    handleFileUpload(e) {
      let selectedFile = event.target.files[0]
      let formData = new FormData()
      formData.append('file', selectedFile)
      formData.append('dir', 'images')
      uploadAction('/file/images/upload', formData).then((res) => {
        const { success, message, data } = res
        if (success) {
          // 触发签单接口
          postAction('/v1/contract/framework/offline/sign', {
            contractFile: data.url,
            contractNo: this.activeInfo.contractNo || '',
          }).then((res) => {
            const { success, message } = res
            if (success) {
              this.reaLoad()
            } else {
              this.$message.error(message)
            }
          })
        } else {
          this.$message.error(message)
        }
      })
    },
    handleUploadContract(item) {
      this.$refs.newUploader.handleOpen(item)
    },
    handleSearch() {
      if (this.rangeDate.length) {
        const [submitStartDate, submitEndDate] = this.rangeDate
        this.queryParams['submitStartDate'] = submitStartDate
        this.queryParams['submitEndDate'] = submitEndDate
      }
      this.handleLoadData(1)
    },
    handleConfirmSplit(item) {
      this.$refs.splitOrderInfoRef.handleShowOrder({ ...item })
    },
    handleEmitSign(record) {
      this.$refs.ReNewSignRef.handleOpen(record)
    },
    /* 开启合同上传 */
    handleOPenContractUploader(item) {
      this.orderNo = item.orderNo
      // 开启抽屉
      this.$refs.UploadContract.handleOpen(item)
    },
    handleLoadData(flag) {
      if (flag) {
        this.pageNation = {
          pageNum: 1,
          pageSize: 20,
          total: 0,
        }
      }
      const { quoteSource, status, contractNo, startDate, endDate, cooperationStatus } = this.queryParams
      let statusList = [], params = { contractNo, startDate, endDate };
      if (quoteSource) {
        quoteSourceList = quoteSource.split(',')
      }
      if (status) {
        statusList = status.split(',')
      }
      if (cooperationStatus) {
        params = { ...params, cooperationStatus: cooperationStatus.split(',') }
      }
      // 创建时间
      if (this.createTimeScoped != '' && this.createTimeScoped != -1) {
        const [createStartDate, createEndDate] = getDateNewRange(this.createTimeScoped)
        params = { ...params, createStartDate, createEndDate }
      } else if (this.createTimeScoped != -1 && this.createTimeRange.length) {
        const [createStartDate, createEndDate] = this.createTimeRange
        params = { ...params, createStartDate, createEndDate }
      }
      if (!this.url && !this.url.list) return this.$message.warning('请设置请求地址')
      let pageParams = { ...this.pageNation }
      delete pageParams.total
      this.loading = true
      this.dataSource = []
      const { pageSize, pageNum } = pageParams
      let api = this.requestType == 'get' ? getAction : this.requestType == 'post' ? postAction : postQueryAction
      api(this.url.list, {
        statusList,
        pagesSize: pageSize,
        pageNum,
        ...params,
      })
        .then((res) => {
          const { success, data } = res
          this.loading = false
          if (success) {
            const { total, list } = data
            this.dataSource = list
            this.pageNation['total'] = total
          }
        })
        .catch((e) => {
          this.loading = false
        })
    },
    /* 参与报价 */
    handleQuote(item) {
      this.$router.push(
        `/quotePriceManagement/quotePriceDetailsView?id=${item.purchaseId}&quoteSource=${item.quoteSource}&quoteId=${item.quoteId}`
      )
    },
    handleSwitchTab(item) {
      const { quoteId, purchaseId, businessId } = item
      this.$router.push(
        `/quotePriceManagement/quotePriceInfoView?purchaseId=${purchaseId}&quoteId=${quoteId}&isDetail=1&businessId=${businessId}`
      )
    },
    reaLoad() {
      this.pageNation = {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      }
      this.queryParams = {}
      this.createTimeRange = []
      this.dataSource = []
      this.handleLoadData(1)
    },
    // 取消报价
    handleCancel(quoteId) {
      postAction('/quote/cancel', {
        quoteId,
      }).then((res) => {
        const { success, message } = res
        if (success) {
          this.$message.success('报价已经取消')
          this.reaLoad()
        } else {
          this.$message.warning(message)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .ant-table {
  color: rgba(0, 0, 0, 0.85);
}

// ::v-deep .ant-table-column-title {
//   font-size: 16px;
// }

::v-deep .ant-table-thead {
  tr {
    background: #f4f5f7 !important;

    th {
      border-bottom: none;
      font-size: 14px;
      font-weight: 600 !important;
      color: #131212;
    }
  }
}

::v-deep .ant-table-tbody {
  tr {
    // &:not(:last-of-type)>td {
    //   border: none;
    // }

    & > td {
      border-bottom: 1px solid #efefef;
    }
  }
}

::v-deep .ant-form-item-control {
  line-height: 30px;
}

::v-deep .ant-form-item-label {
  line-height: 30px;
}

::v-deep .ant-table-thead > tr > th {
  background: #efefef;
  border-bottom: none;
  font-weight: 500;
}

.searchInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;

  .option-area {
    margin-left: 8px;
    .ant-btn {
      width: 97px;
      height: 38px;

      & + .ant-btn {
        margin-left: 8px;
      }
    }
  }
}

@import '~@/styles/list.less';

::v-deep .ant-form-item-label {
  width: 67px !important;
}

.fileUploader {
  display: none;
}

.pickerDict {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  min-width: 140px;
  height: 26px;
  color: #131212;
  cursor: pointer;
  padding: 0 6px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;

  .placeholder {
    color: #999;
  }
}

.row_title {
  display: flex;
  align-items: center;
}

img.newIcon {
  width: 16px;
  margin-left: 6px;
}
.inline-name {
  width: 100%;
  display: flex;
  align-items: center;
  span.label {
    display: inline-block;
    width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
span.tag {
  display: inline-block;
  width: 32px;
  height: 20px;
  border-radius: 3px;
  font-size: 12px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  background: #05c29c;
  margin-right: 6px;
  &.danger {
    background: #ee4261;
  }
}
</style>
